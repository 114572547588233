import $ from "jquery";

document.addEventListener("DOMContentLoaded", () => {
    const navToggle = $(".web-navigation-mobile__toggle");
    const dropArrow = $(".web-navigation-mobile__dropdown-btn i");


    dropArrow.on("click", (event) => {
        $(event.target).parent().next().slideToggle();
    });

    navToggle.on("click", () => {
        $(".web-navigation-mobile__nav").slideToggle();
    });
});